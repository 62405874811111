import React, { useState, useEffect, useContext } from "react";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import ZodiacDD from "../../../components/ZodiacDD";
import banner from "../../../staticAssets/Horoscope Bg.png";
import logo from "../../../staticAssets/Leo.png";
import axios from "axios";
import { navigate } from "gatsby";
import { AuthContext } from "../../../context/Auth";
import { Helmet } from "react-helmet-async";
import { AnalyticsContext } from "../../../context/Analytics";

function Index({location}) {
  const [blogs, setblogs] = useState([]);
const { gtmPageViewEvent } = useContext(AnalyticsContext) || {
    gtmPageViewEvent: () => {},
  };
  const { user } = useContext(AuthContext) || {
    user: {},
  };
  useEffect(() => {
    const fn = async () => {
      let res = await axios.get(
        "https://www.bodhi.app/blog/wp-json/wp/v2/posts"
      );

      if (res.status == 200) {
        setblogs([...res.data]);
      }
    };
    gtmPageViewEvent(window.location.pathname, window.location.host);
fn();
  }, []);
  return (
    <div className="h-screen flex flex-col block hide-scroll-bar scrollbar-hide worksans w-screen overflow-x-clip">
      <Header />
      <Helmet>
        <link
          rel="canonical"
          href={"https://www.bodhi.app"+location?.pathname}
        />
      </Helmet>
      <div className="flex-1 overflow-y-auto flex flex-col">
        <main className="grow hide-scroll-bar scrollbar-hide">
          <div className=" w-full relative bg-[#FFEEEF] overflow-hidden">
            <img className="h-32 md:h-auto w-full object-fill" src={banner} />
            <div className="absolute top-0 w-full h-full flex justify-center items-center">
              <h1 className="text-center text-2xl md:text-4xl font-semibold text-white">
                Leo Facts
              </h1>
            </div>
          </div>
          <div className="container mx-auto flex flex-col">
            <div className="flex flex-wrap my-2 ml-2">
              <a
                href="https://www.bodhi.app/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Bodhi</h4>
              </a>
              
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Zodiac Signs</h4>
              </a>
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/leo"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Leo</h4>
              </a>
              
              <h4 className="mx-2 text-sm">/</h4>
              <h4 className="text-sm">Leo Facts</h4>
            </div>
            <div className="flex mt-10 items-center">
              <div>
                <img className="h-24 md:h-48" src={logo} />
              </div>
              <div className="flex-1 flex flex-col md:flex-row">
                <div className=" flex-1 flex flex-col">

                <h1 className="font-semibold text-xl md:text-4xl ml-4">Leo Facts</h1>
                <h2 className="text-sm md:text-base ml-4">July 23 - August 22</h2>
                </div>
                <div className="">
                <ZodiacDD name={"Change Sunsign"} />
              </div>
              </div>
              
            </div>
            <div className="grid grid-cols-3 md:grid-cols-4 gap-2 md:gap-8 mt-10 text-[0.8rem] md:text-base mx-1">
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2 py-1" onClick={() => {
                    navigate("/zodiac-signs/leo/leo-woman");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Leo Woman</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>

              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/leo/leo-health");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Leo Health</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/leo/leo-career");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Leo Career</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/leo/leo-love");
                  }}>
                  <h5 className="font-semibold text-black">Leo Love</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/leo/leo-nature");
                  }}>
                  <h5 className="font-semibold text-black">Leo Nature</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/leo/leo-man");
                  }}>
                  <h5 className="font-semibold text-black">Leo Man</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/leo/leo-relationship");
                  }}>
                  <h5 className="font-semibold text-black">Leo Relationship</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/leo/leo-traits");
                  }}>
                  <h5 className="font-semibold text-black">Leo Traits</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/leo/leo-facts");
                  }}>
                  <h5 className="font-semibold text-black">Leo Facts</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
            </div>
            <span className="my-4 mx-1 md:mx-0">
          <p className="playfair text-black text-xl md:text-3xl">Interesting facts about Leo</p> <br></br>
          <br></br>
          <p className="font-bold text-orange-500 inline-block mr-2">Leo Facts:</p>
          Powerful, royalty, wisdom, intelligent, wild at times, loyal, savage, headstrong, enthusiastic, independent, rebellious, visionary, spontaneous, stubborn, clever, adventurous <br></br>
          <br></br>
          <p className="font-bold text-orange-500 inline-block mr-2">Leo Problem:</p>
          According to your personality, you get sentimental over the smallest things at times, and the reason behind this is that you can’t stand watching someone do something when you know how to do it in a better way. <br></br>
          <br></br>
          <p className="font-bold text-orange-500 inline-block mr-2">Leo, when pissed off:</p> <br></br>
          <br></br>
          You’re a social person, and you need social interaction. You crave the company of others, and you feel most alive when you’re talking and interacting. You get irritated when you have to fight for someone’s attention. It would help if you were the center of attention, and it felt like a void. <br></br>
          <br></br>
          <p className="playfair text-black text-xl md:text-3xl">Things Leo hates</p> 
          <br></br>
          You’re independent, and you don’t like being taken for granted. You don’t like being forced into conversations or friendships. You’ll often pass on opportunities to be sociable if you don’t feel like you have a genuine interest in the other person. If someone takes you for granted, you may feel the need to show them just how much you value yourself. But because you’re so independent, you may not express this desire loudly. <br></br>
          <br></br>
          <p className="playfair text-black text-xl md:text-3xl">Leo does things when hurt or upset</p><br></br>
          You may become heartless if you feel taken for granted. You may become unwilling to speak to the person or group that has done the taking forgiven. Sometimes, you think heartless, and you don’t want to talk to anyone when you feel this emotion. <br></br>
          <br></br>
          <p className="playfair text-black text-xl md:text-3xl">Things Leo like</p> <br></br>
          You love to keep courting, dating, giving, and romance going as long as the other person seems willing. You love to be unique and enjoy every moment. You are never one to turn down a request for help because you believe that everyone can use a helping hand now and then. <br></br>
          <br></br>
          <p className="font-bold text-orange-500 inline-block mr-2">Leos at their lowest points:</p>Yes! You are short-tempered and want someone to help you at your lowest points. <br></br>
          <br></br>
          <p className="playfair text-black text-xl md:text-3xl">How to catch Leo's attention</p>
          You get inspired when someone is alluring and fun-loving. <br></br>
          <br></br>
          <p className="playfair text-black text-xl md:text-3xl">Perfect gift for Leo</p> <br></br>
          Gift certificates, store gift cards, and other similar items are perfect for you. You also love to collect family heirlooms, keepsake albums, and other similar items that remind you of the people and experiences of the past. <br></br>
          <br></br>
          <p className="font-bold text-orange-500 inline-block mr-2">Leo motto:</p>Love me or leave me, go hard or go home <br></br>
          <br></br>
          Learn more about your personality with a free consultation from Janampatri. We’ll use our in-depth knowledge of astrology to interpret your natal charts and provide you with a detailed personality profile. <br></br>
          <br></br>
            </span>
          </div>
          <div className="container mx-auto">
            <div className="flex justify-between mb-4 mx-2">
              <h1 className="text-xl md:text-3xl text-black playfair">
                Recent Blogs
              </h1>
              <a
                className="text-sm md:text-md text-orange-500 text-center font-semibold"
                href="https://www.bodhi.app/blog/"
              >
                See More
              </a>
            </div>
            <div className="relative">
                <div className="absolute top-0 right-0 w-0 md:w-36 fixed h-full bg-gradient-to-r from-transparent to-white"></div>
              <div className="flex overflow-x-scroll pb-4 mt-2 hide-scroll-bar scrollbar-hide">
                <div className="flex flex-nowrap ml-2">
                  {blogs.slice(0, 10).map((item, i) => {
                    return (
                      <a href={item.link} key={i} target="_blank">
                        <div className="flex flex-col h-80 w-52 justify-start items-start bg-gray-100 mr-10 mb-10 rounded-lg overflow-hidden hover:shadow-lg">
                          <img
                            className="object-cover w-52 h-52 w-auto bg-gray-100"
                            src={
                              item?.yoast_head_json?.og_image
                                ? item?.yoast_head_json?.og_image[0]?.url
                                : ""
                            }
                          />
                          <span className="mt-2 block mx-2 text-center">
                            {item?.yoast_head_json?.title ?? ""}
                          </span>
                        </div>
                      </a>
                    );
                  })}
                  {/* <div className="flex h-80 w-52 justify-center items-center "></div> */}
                </div>
              </div>
            </div>
          <button
            className="justify-center text-white fixed right-2 bottom-2 rounded-t-2xl but px-4 py-2 flex gap-1 shadow-xl"
            onClick={() => {
              navigate("/consultAstro");
            }}
          >
            {user.name !== undefined?`Consult Top Astrologers Now!`:`Login & get ${
              user?.currencyType == "usd" ? "$ 4" : "₹ 91"
            } in your account!`}
          </button>
          </div>
        </main>
        <Footer />
      </div>
    </div>
  );
}

export default Index;
